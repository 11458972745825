import React, { useEffect, useState, useMemo } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { selectedGridRowsCountSelector } from "@mui/x-data-grid";
import { Button, Typography, Grid } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Swal from "sweetalert2";
const ITEM_HEIGHT = 51;
const ITEM_PADDING_TOP = 3;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export const MUIDropDown = (props)=>{
  console.log(props)
  const [selectedEvents,setSelection]  = useState(props.original_events?props.original_events:[])
  const [originalEvents, setOriginalEvents] = useState(props.original_events ? props.original_events : [])
  var timeoutID;
  // const [open, setOpen] = useState(false);
  const addToProgressBar = () => {
    var progress_bar = document.getElementsByClassName('sd-progress-buttons__container')[0];
    var add_btn = document.getElementById('add_remove_event');
    add_btn?.setAttribute("style", "display: flex;");
    console.log(progress_bar)
    progress_bar.appendChild(add_btn)
    // document.getElementsByClassName("hnUIWU")[0].setAttribute("style","box-shadow:none")
    
  }
  const events = ['airway', 'eye', 'cardiac', 'neurology', 'psych', 'dental', 'meta','musculoskeletal', 'misc']
  const event_name_map ={
    'airway': 'Airway',
    'eye': 'Eye',
    'cardiac': 'Cardiac',
    'neurology': 'Neurology', 
    'psych': 'Psychological',
    'dental': 'Dental',
    'meta': 'Metabolic',
    'musculoskeletal': 'Musculoskeletal',
    'misc': 'Miscellaneous'
  }
  
  const handleApply = () => {
    var add_pages = [];
    var remove_pages = []
    events.forEach((event)=>{
      if (originalEvents.includes(event) && !selectedEvents.includes(event)){
        remove_pages.push(event)
      }
      else if (!originalEvents.includes(event) && selectedEvents.includes(event)) {
        add_pages.push(event)
      }
    })
    console.log({ "add": add_pages, "remove": remove_pages })
    return { "add": add_pages, "remove": remove_pages }
  }
  const handleChange = (event) => {
    setSelection(event.target.value)
  };
  
  useEffect(()=>{
    timeoutID = setTimeout(addToProgressBar(), 500)

    return(()=>{
      clearTimeout(timeoutID)
    })
  },[])

  const selectionApplied = (page_changes) => {
    if (page_changes["remove"].length != 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Delete Event?',
        text: 'The data corresponding to the deleted event will be removed permanently',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        confirmButtonColor: '#3EB489',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (!result.isConfirmed) {
          setSelection(originalEvents)
        }
        else {
          setOriginalEvents(selectedEvents)
          props.event_changes(page_changes)
        }
      });

    }
    else {
      setOriginalEvents(selectedEvents)
      props.event_changes(page_changes)
    }

  }
  return <div 
    id="add_remove_event" 
    className="br3 bg-mint pa2 w-10 fr" 
    >
      
      {/* <Typography sx={{fontWeight:500, mb:1}}>
        <b>Add/Remove Events</b>
      </Typography>
      <Autocomplete
        multiple
        value={selectedEvents}
        options={events}
        onChange={ (event, newValue) => {
          setSelection(newValue)
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, newValue) => {
          return option.value === newValue.value;
        }}
        sx={{ width: "24ch", 
        alignSelf:"center",
        backgroundColor: "white"}}
        renderInput={(params) => <TextField {...params} label="Selected Events" />}
        /> */}
        <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
        >
      
      <Grid item xs={12}>
        <Typography variant="body1" sx={{mb: 1}}>
          <b>
            <center>
            Add/Delete Events
            </center>
          </b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <center>
        <FormControl >
          <InputLabel id="multiple-checkbox-label">Selected Events</InputLabel>
          <Select
            sx={{
              width: "28ch",
              backgroundColor: "white"
            }}
            labelId="multiple-checkbox-label"
            id="multiple-checkbox"
            multiple
            // open={open}
            value={selectedEvents}
            onChange={handleChange}
            input={<OutlinedInput label="Selected Events" />}
            renderValue={(selected) => selected.map(event => event_name_map[event]).join(', ')}
            MenuProps={MenuProps}
          >
            {events.map((event) => (
              <MenuItem key={event.value} value={event}>
                <Checkbox checked={selectedEvents.includes(event)} />
                <ListItemText primary={event_name_map[event]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </center>
      </Grid>
      
      <Grid item xs={12}>
        <center>
      <Button 
        variant="contained" 
        sx={{mt:2, mr:2}}
        onClick={()=>{
          let page_changes = handleApply()
          selectionApplied(page_changes)
          
        }}
      >
        Apply
      </Button>
      
      <Button onClick={()=>{
        setSelection([])
        }}
        variant="contained" sx={{ mt: 2 }}>
        Reset
      </Button>
        </center>

      </Grid>

      </Grid>
    </div>
}
