import React, { FC, useEffect, useState, useCallback } from "react";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { composeJSON, pagesJSON } from "./survey_templates/SurveyComposer";

// import DDButton from "./DropDownButton";
import "./css/tachyons.min.css"

//Import localization
import './index.css';
import axios from "axios";
import { service_url } from "./config";

import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
// StylesManager.applyTheme("modern");

var page_json_map = {
    "Neurologic": "neurology", "Psychological": "psych", "Airway/Respiratory": "airway", "Eye": "eye", "Cardiac": "cardiac",
    "Dental": "dental", "Metabolic": "meta", "Musculoskeletal": "musculoskeletal", "Miscellaneous": "misc"
}
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoadSingleSurvey = props => {
    let query = useQuery();
    let navigate = useNavigate();
    const surveyUUID = query.get("surveyUUID");
    // const reviewType = query.get("type");

    
    const [surveyPayload, setsurveyPayload] = useState(null);
    var survey = new Model();
    var json, data;
    survey.applyTheme(SurveyTheme.DefaultLightPanelless);
    const loadSurveyFromDB = (surveyUUID) => {
        var dataString = {
            "surveyUUID": surveyUUID,
            "reviewFlag": query.get("type")
        };
        var config = {
            headers: { 'Access-Control-Allow-Origin': '*' }
        };

        axios.post(service_url + "/surveysession/getSurvey", dataString, config)
            .then(function (response) {
                let payload = {}
                try{

                    payload = JSON.parse(JSON.parse(response.data))
                    let test = "pages" in payload
                }
                catch{
                    payload = JSON.parse(response.data)
                    let test = "pages" in payload
                }
                setsurveyPayload(payload);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    useEffect(() => {
        loadSurveyFromDB(surveyUUID);
        // const completeAction = survey.navigationBar.getActionById('sv-nav-complete');
        // completeAction.visible = true;
    }, []);


    if (surveyPayload) {
            console.log("surveypayload loaded")
            console.log(typeof(surveyPayload))
            if ("pages" in surveyPayload) {
                console.log(surveyPayload["pages"]);
                let events = []
                for (let i = 0; i < surveyPayload["pages"].length; i++) {
                    if (surveyPayload["pages"][i] === 'Somatic') {
                        events.push(page_json_map["Musculoskeletal"])
                    }
                    else {
                        events.push(page_json_map[surveyPayload["pages"][i]])
                    }
                }
                console.log("Events:", events)
                json = composeJSON(events);
            }
            else {
                console.log("Loading questionnaire");
                json = JSON.parse(surveyPayload["questionnaire"])
            }
        data = surveyPayload["data"];
        survey = new Model(json)
        survey.data = data;
        survey.mode = "display"
        survey.showCompleteButton= true
        survey.completeText = "Finish";
    
        // functions run on survey complete
        survey
            .onComplete
            .add(function (sender) {
                // document.documentElement.querySelector('#surveyResult').textContent = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
                navigate('/')
    
            });
    
        // survey.onCurrentPageChanged.add(() => {
            // var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');
    
            // for (var i = 0; i < elemets.length; i++) {
            //     var div = elemets[i];
            //     div.setAttribute("style", "font-size:13px");
            // }
    
        // })
    }
    
    
            


    return (
        <>
        {!surveyPayload && <div className="App"> Loading Results ...</div>}
        {surveyPayload &&
        <>
        <div className="tl fl mt5 w-100">
            <Survey model={survey} />
        </div>

        </>}
        </>
    );

};

export default LoadSingleSurvey;
