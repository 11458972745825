import React, {FC, useEffect, useState} from "react";
import "./css/tachyons.min.css"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v5 as uuidv5 } from 'uuid';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from "@mui/material";
import axios from "axios";
import { email_service, service_url, mailto_list } from "./config";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Box, Input} from "@mui/material";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";

  
const sendImages = (uploadedFiles, support_uuid = "test") => {
  if (uploadedFiles) {
    var formData = new FormData();
    var totalfiles = uploadedFiles.length;
    console.log(totalfiles)
    for (let index = 0; index < totalfiles; index++) {
      console.log(uploadedFiles[index])
      formData.append(uploadedFiles[index]['name'], uploadedFiles[index]['file'], );
    }
    formData.append("uuid",support_uuid)
    // for (var key of formData.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }
    console.log(formData.get(uploadedFiles[0]['name']))
    return axios.post(email_service + '/upload_screenshots', formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        'Access-Control-Allow-Origin': '*'
      },
      data: formData,
    })
   
  }
  return 
}


const SupportForm = (props) => {
  const [title,setTitle] = useState('')
  const [type, setType] = useState('clinical_support')
  const [description,setDescription] = useState('')

  
  
  return (
    <Container sx={{width:"75%"}}>
      <FormGroup sx={{my: 3}}>
        <FormLabel>
          <span className="b fl">
            Title
          </span>
        </FormLabel>
        <TextField placeholder="What is the issue?" value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
      </FormGroup>
      

      
      <FormGroup sx={{ my: 3 }}>
      <FormLabel className="fl b">
          <span className="b fl">
            Type
          </span>
      </FormLabel>
        <Select 
          aria-label="Select Type" 
          value={type} 
          onChange={(e) => { setType(e.target.value) }}
          sx={{ justifyContent: "flex-start", maxWidth:"45%", mt:1 }}
          // input={(inputProps) => <TextField {...inputProps} sx={{display: "flex"}}  />}
        >
          <MenuItem value={"clinical_support"}>Clinical Support</MenuItem>
          <MenuItem value={"tech_support"}>Technical Support</MenuItem>
          <MenuItem value={"admin_support"}>Administrative Support</MenuItem>
          <MenuItem value={"other_support"}>Other</MenuItem>
        </Select>

      </FormGroup>  

      <FormGroup>
        <FormLabel>
          <span className="b fl">
            Description
            </span>
        </FormLabel>
        <FormControl 
          as="textarea" 
          rows={5} 
          placeholder="What is the issue?" 
          value={description} 
          onChange={(e) => { setDescription(e.target.value) }}
          sx={{border:1, borderRadius:2, p:1}}
        />

        </FormGroup>

      <FormGroup sx={{ mt: 3, mb:1 }}>
        <FormLabel sx={{mt:2, mb:2, justifyContent:"flex-start"}}>
          <span className="b tl fl w-100">
          Upload Screenshots (PNG, JPEG, PDF only. Max file size of 10MB and maximum of 10 files allowed)
          </span>
        </FormLabel>
        {/* <FormControl>
          <Button
            variant="contained"
            component="label"
            sx={{width:"30%"}}
            startIcon={<CloudUploadIcon />}
          >
            <input
            type="file"
            hidden
            accept=".png,.jpeg,.jpg,.pdf"
            multiple
            onChange={(e) => props.upload_files(e.target.files)}
            
            />
            Upload
        </Button>
        </FormControl> */}
        <Dropzone 
        onChange={props.upload_files} 
        value={props.uploaded_files}
        accept=".png, .jpg, .pdf"
        maxFiles={5}
        maxFileSize={10*1024*1024}
        >
          {props.uploaded_files.map((file) => (
            <FileMosaic {...file} preview />
          ))}
        </Dropzone>

      </FormGroup>
      <br/><br/>
      <Button variant="contained" onClick={(e)=>{props.submitHandler(e,{"title":title,"description":description, "issue_type":type})}}>
        Submit
      </Button>
      
    </Container>
  );
}

const HelpDesk = ()=>{
    const [uploadedImages, setImages] = useState([])
    const namespace = '1b0db098-ef29-468a-98be-40d58c91b2c0'
    let user_name = sessionStorage.getItem("user_name") || ''
    let user_id = sessionStorage.getItem("user_id") || ''
    let user_email = sessionStorage.getItem("user_email")|| ''
    let navigate = useNavigate();

  useEffect(() => {
    console.log("IMAGES:",uploadedImages)
  }, [uploadedImages])
  const alert_function = (type, uuid = '') => {

    let message = "Please try again or <a href="+{ mailto_list }+" target='_blank'>email support</a>"
    let title = "Something went wrong"
    let allowOutsideClick = true
    if (type === 'upload') {
      message = "Unable to send the uploaded images to the server<br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a><br>"
    }
    else if (type === 'email') {
      message = "Unable to send the email with the ticket number to you<br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a>"
    }
    else if (type === 'submit') {
      message = "Unable to submit your ticket to WUS support <br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a>"
    }
    else if (type === 'success') {
      title = "Ticket Submitted"
      message = " Please keep a copy of this ticket number <br> \
                <div id='bar'><b>"+ uuid + "</b></div>"
        allowOutsideClick = false
    }

    Swal.fire({
      title: title,
      icon: type === "success" ? "success" : "error",
      html: message,
      allowOutsideClick: allowOutsideClick
    }).then((result) => {
      if(type === "success"){
        navigate("/")
      }
    })
  }
    const submit = (e,formData)=>{
      document.body.style.cursor = 'progress'
      let post_data= formData
      post_data['user_name'] = user_name
      post_data['status'] = 'Open'
      // post_data['ticket_uuid'] = sp_map[formData['type']]+user_name+Date.now()
      post_data['ticket_uuid'] = uuidv5(user_id +'_ticket_'+Date.now(), namespace)
      console.log(post_data)
      // console.log(email_service)
      var config = {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      };

      let email_post_data = post_data
      email_post_data["user_email"]=user_email
      axios.post(service_url + '/submit_ticket', post_data, config).then(
        (response) => {
          alert_function("success", post_data['ticket_uuid'])
          document.body.style.cursor = 'default'
        }, (error) => {
          alert_function("submit")
          document.body.style.cursor = 'default'
      })
      if (uploadedImages)
      {
        console.log("uploaded images section")
        sendImages(uploadedImages, post_data['ticket_uuid'])?.then((image_response) => {
          axios.post(email_service + '/sendTicketEmail', email_post_data, config).then(
            (email_response) => {
            },(email_error)=>{
              console.log(email_error)
          })
        },(error)=>{
          console.log(error)
          
        })
      }
      else{
        axios.post(email_service + '/sendTicketEmail', email_post_data, config).then(
          (email_response) => {
            console.log("email sent")
          }, (email_error) => {
            // alert_function("email")
            document.body.style.cursor = 'default'
          })
      }
    }

    return (
    <div className="fl w-60 mt5 pb2">
        <h2>Wake Up Safe Support</h2>
        <SupportForm submitHandler={submit} upload_files={setImages} uploaded_files={uploadedImages} />
    </div>
    )
}
export default HelpDesk;