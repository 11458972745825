import React, {FC, useEffect, useState} from "react";
import { BrowserRouter, Route, Link, useNavigate} from "react-router-dom";
import background from "./img/baby_bg.jpg";
import logo from "./img/wake_up_safe_logo.png";
import './css/landingpage.css'
import { useSessionStorage } from './useSessionStorage';
import Copyright from "./Copyright";
import { version_num, dashboard_url } from "./config";

import { Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { Grid, Typography, Box, Container, Button} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";
// import { MUIDropDown } from "./MUIdropDown";
const theme = createTheme({
    palette: {
        primary: {
            main: "#5ac69f",

        },
        secondary: {
            main: 'rgba(0,0,0,0.54)',
        },
        background: {
            default: '#D9D9D9',
        },
    },
});

const LandingPage = () => {
    const [surveyPayload, setSurveyPayload] = useSessionStorage('survey',null);
    const [caseSubmitFlag, setCaseSubmitFlag] = useSessionStorage('case_submit_flag',false);
    const [surveyUUID, setSurveyUUID] = useSessionStorage('survey_uuid',null);
    const [sessionLoad, setSessionLoad] = useState(false)
    const [submitShadow, setSubmitShadow] = useState(1)
    
    let navigate = useNavigate();
    useEffect(()=>{
        setCaseSubmitFlag(false);
        setSurveyPayload({});
        setSurveyUUID(null);
        setTimeout(()=>{
            setSessionLoad(true)
        },250)

        console.log("Landing Page Loaded")
    },[]);
  

    return (

        <>
        
            <Container
                sx={{
                    backgroundImage: `url("./img/baby_bg.jpg")`,
                    backgroundSize: "cover",
                    height: "100vh",
                    minWidth:"90%"
                }}
                
            > 
            <Grid container sx={{height: "100%"}} justifyContent={'space-between'} alignItems={'center'} spacing={6}>
            <Grid item xs={4}>
                <Card 
                            sx={{ minWidth: 300, boxShadow: 5}}
                            onClick={() => { navigate('/select_clinical_events') }}
                >
                <CardActionArea
                    className="grow dib shadow-5"
                >
                    <CardMedia
                        component = "img"
                        sx={{ height: 200 }}
                        image="img/submit.png"
                        title="Submit New Case"
                    />
                    <CardContent>
                        <Typography 
                            gutterBottom variant="h6" component="div"
                            fontSize={18}
                            color={"#00acc1"}
                            fontWeight={"bold"}
                            sx={{ fontFamily:"Roboto Black, sans-serif"}}

                        >
                            Submit New Case
                        </Typography>
                        
                    </CardContent>
                </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={4}>
                        <Card sx={{ minWidth: 300, boxShadow: 5}}
                            
                        >
                            
                <CardActionArea
                    className="grow dib"
                >
                    <CardMedia
                        component="img"
                        sx={{ height: 200 }}
                        image="img/review.jpeg"
                        title="Submit New Case"
                    />
                    <CardContent>
                        <Typography 
                        gutterBottom variant="h6" component="div"
                                        fontSize={18}
                                        color={"#00acc1"}
                                        fontWeight={"bold"}
                                        sx={{ fontFamily: "Roboto Black, sans-serif" }}
                        >
                            Review
                        </Typography>
                        <ThemeProvider theme={theme}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                            <Grid item xs={5}>
                                <Button
                                    sx={{color:"white"}}
                                    variant = 'contained'
                                    onClick={()=>{
                                        navigate('/review_cases')
                                    }}
                                >
                                    <strong>Submitted Cases</strong>
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    sx={{ color: "white" }}
                                    variant='contained'
                                    disabled={sessionStorage.getItem('role') === '0' && sessionStorage.getItem('site_id') === '0'}
                                    onClick={() => {
                                        navigate('/review_working_cases')
                                    }}
                                >
                                    <strong>Working Cases</strong>
                                </Button>
                            </Grid>
                        </Grid>
                        </ThemeProvider>

                    </CardContent>
                </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card 
                        sx={{ minWidth: 300, boxShadow: 5}}
                >
                            
                <CardActionArea
                    className="grow dib"
                >
                    <CardMedia
                        component="img"
                        sx={{ height: 200 }}
                        image="img/dashboard.png"
                        title="Submit New Case"
                    />
                    <CardContent>
                                    <a href={dashboard_url + "?key=" + sessionStorage.getItem('user_id') + "&id=" + JSON.parse(sessionStorage.getItem('web_session_id'))} style={{"text-decoration":"none"}} target='_blank'>
                        <Typography 
                        gutterBottom variant="h6" component="div"
                                        fontSize={18}
                                        color={"#00acc1"}
                                        fontWeight={"bold"}
                                        sx={{ fontFamily: "Roboto Black, sans-serif" }}
                        >
                            Summary Statistics Dashboard
                        </Typography>
                        </a>

                    </CardContent>
                </CardActionArea>
                </Card>
            </Grid>
        </Grid>
        <Copyright/>
        </Container>
        </>
        // <div id="cards_landscape_wrap-2" style={{ backgroundImage:`url(${background})` }}>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-xs-12 col-md-4" style={{ padding: 40 }}>
        //                 <a href="">
        //                     <Link to="/select_clinical_events">
        //                     <div className="card-flyer">
        //                         <div className="text-box">

        //                             <div className="image-box">
        //                                 <img src={'./img/submit.png'} alt="" />
        //                             </div>
        //                             <div className="text-container">
        //                                  <h6>Submit New Event</h6>
        //                                 <p></p>

        //                             </div>

        //                         </div>
        //                     </div>
        //                     </Link>
        //                 </a>
        //             </div>

        //             <div className="col-xs-12 col-md-4" style={{ paddingLeft: 10, paddingRight:10, paddingBottom:40, paddingTop:40  }}>
                        
        //                     <div className="card-flyer">
        //                         <div className="text-box pb2">
        //                             <div className="image-box">
        //                                 <img src={'./img/review.jpeg'} alt="" />
        //                             </div>
        //                             <div className="text-container">
        //                             <h6>Review </h6>
        //                                 <div className="w-100 mv3">
        //                                 <a href="" className="grow dib fl w-48">
        //                                         <Link to="/review_cases">
        //                                         <span className=" br2 bg-mint-important white f45 b pa2">Submitted Cases</span>
        //                                         </Link>
        //                                 </a>
        //                                 <a href="" className="grow dib fr w-48">
        //                                         <Link to="/review_working_cases">
        //                                         <span className="br2 bg-mint-important white f45 b pa2">Working Cases</span>
        //                                         </Link>
        //                                 </a>    
        //                                 </div>
        //                             </div>

        //                         </div>
        //                     </div>
                        
        //             </div>
        //             {sessionLoad&&<div className="col-xs-12 col-md-4" style={{ padding: 40 }}>
        //                 <a href={dashboard_url+"?key="+sessionStorage.getItem('user_id')+"&id="+JSON.parse(sessionStorage.getItem('web_session_id')||'')} target='_blank'>
        //                         <div className="card-flyer">
        //                             <div className="text-box">

        //                                 <div className="image-box">
        //                                     <img src={'./img/dashboard.png'} alt="" />
        //                                 </div>
        //                                 <div className="text-container">
        //                                     <h6>Summary Statistics Dashboard</h6>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                 </a>
        //             </div>}
        //         </div>
        //         {/* <div className="row">

        //         </div> */}

        //         {/* <div className="row justify-content-center align-items-center opacity-50"  style={{ top: '5%',position: 'absolute', left:'5%'}} >
        //             <img src={logo} alt="Logo" style={{width: 250, height: 80}} />
        //         </div> */}

        //     </div>

            

        // </div>



    );
}

export default LandingPage;