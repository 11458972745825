// load survey from storage system
import axios from "axios";
import { service_url, email_service } from "../config";

export const storeWebSession = () => {
    let auth_status = JSON.parse(sessionStorage.getItem('auth_status'))
    // console.log(auth_status);
    if(auth_status==='unauthenticated'){
        // console.log("Not Authenticated NO WS dump")
        return
    }
    var dataString = {};
    dataString['WebSessionID'] = JSON.parse(sessionStorage.getItem('web_session_id'));
    dataString['SurveyUUID'] = JSON.parse(sessionStorage.getItem('survey_uuid'));
    dataString['UserName'] = sessionStorage.getItem('user_name');
    dataString['maxinactivetime'] = Date.now()-JSON.parse(sessionStorage.getItem('timer'));
    dataString['CaseSubmitFlag'] = JSON.parse(sessionStorage.getItem('case_submit_flag'));

    if (!dataString['SurveyUUID']){
        // Survey not yet started
        console.log("survey not started")
        return;
    }
    // console.log(dataString);
    var config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    };
    const res =  axios.post(service_url+"/websession", dataString, config)
    .then(function(response){
        // console.log("Response", response)
    })
    .catch(function(error){
        // console.log(error)
        throw(error)
    });

    // console.log(res);
    return res;
}

export const storeSurveySession = () => {
    var dataString = {};
    dataString['SurveyUUID'] = JSON.parse(sessionStorage.getItem('survey_uuid'));
    console.log(dataString['SurveyUUID'])
    dataString['SurveySubmitFlag'] = JSON.parse(sessionStorage.getItem('case_submit_flag'));
    dataString['payload']= JSON.parse(sessionStorage.getItem('survey_payload'));
    dataString['UserName']= sessionStorage.getItem('user_name');
    //Event Types
    // console.log(dataString["EventType"])
    dataString["EventType"] = dataString['payload']["pages"]
    dataString["EventType"] = dataString["EventType"].join(',')

    // console.log("Survey Session flag", dataString['SurveySubmitFlag']);
    // alert("Dumping Web Session");
    if (!dataString['SurveyUUID']){
        console.log("survey not started")
        // Survey not yet started
        return;
    }
    var config = {
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    };
    const res =  axios.post(service_url+"/surveysession", dataString, config)
        .then(function(response){
            // console.log(response);
            //Perform action based on response
        })
        .catch(function(error){ 
            // console.log(error);
            //Perform action based on error
            throw(error)
        });

    // console.log(res);
    return res;
}



export const writeUser2DB = (data) => {
    var config = {
        headers: {'Access-Control-Allow-Origin': '*'}
    };
    axios.post(service_url+"/user", data, config)
        .then(function(response){
            // console.log(response);
            //Perform action based on response
        })
        .catch(function(error){
            // console.log(error);
            //Perform action based on error
        });

}



export const sendDataToDataAPI = (data) => {
    var config = {
        headers: {'Access-Control-Allow-Origin': '*'}
    };

    axios.post(service_url, data, config)
        .then(function(response){
            // console.log(response);
            //Perform action based on response
        })
        .catch(function(error){
            // console.log(error);
            //Perform action based on error
        });
}

// load survey from data service by a surveyUUID
export const loadSurveyFromDB = (dataString) => {
    console.log(dataString)
    var config = {
        headers: {'Access-Control-Allow-Origin': '*'}
    };

    axios.get(service_url+"/surveysession/getSurvey",dataString,config)
        .then(function(response){
            // console.log(response);
        })
        .catch(function(error){
            // console.log(error);
        });

}



const postData = async(url = '', data = {}) => {
    
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {'Access-Control-Allow-Origin': '*'},
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
      keepalive: true
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
export const userLogoutDB = () => {
    // var config = {
    //     headers: {'Access-Control-Allow-Origin': '*'},
    //     // httpAgent: new http.Agent({ keepAlive: true }),
    //     // httpsAgent: new https.Agent({ keepAlive: true })
    // };

    var dataString = {
        "UserID": sessionStorage.getItem("user_id"), 
        "UserName":sessionStorage.getItem("user_name"),
        "signup_login":false,
        "LoginStatus":false
    };
    postData(service_url+"/user",dataString)
        .then(function(response){
            // console.log(response);
        })
        .catch(function(error){
            // console.log(error);
        });

}



