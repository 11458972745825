import { useEffect, useState } from 'react';
import axios from "axios";
import './css/tachyons.min.css'
import UserTable from "./UserTable.jsx"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { email_service, request_config, mailto_list } from "./config";
var user_list = []
var ticket_list = []

var inst_map = {}

const role_map = {
    0: "Super User", 1: "Administrator", 2: "IT", 3: "Provider"
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function createRowElement(dataElement) {
    let username = dataElement['UserName']
    let name = dataElement['LastName'] + ", " + dataElement['FirstName']
    let email = dataElement["Email"]
    let status = dataElement["Status"]
    let role = role_map[dataElement["roleID"]]
    let care_site = inst_map[dataElement["siteID"]]
    let request_time = dataElement["SignupTime"]

    let row_elem = {
        "user_name": username,
        "full_name": name,
        "email": email,
        "care_site": care_site,
        "role": role,
        "req_time": request_time,
        "status": status
    }
    return row_elem

}

const UserApproval = (props) => {
    const [tableData, setTableData] = useState([]);
    const [tabvalue, setTabValue] = useState(0);
    const location = useLocation()


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const retrieve_users = () => {
        axios.post(email_service + '/care_sites', {}, request_config)
            .then((response) => {
                inst_map = response.data['inst_map']
                const site_id = sessionStorage.getItem('site_id')
                const role = sessionStorage.getItem('role')
                axios.post(email_service + "/getAllUsers", {"site_id": site_id, "role": role}, request_config)
                    .then((response) => {
                        user_list = response.data
                        var table_rows = []
                        for (let i = 0; i < user_list.length; i++) {
                            table_rows.push(createRowElement(user_list[i]))
                        }
                        console.log(user_list[0])
                        setTableData(table_rows);
                    }, (error) => {
                        //   console.log(error);
                        console.log("Unable to get user data", error)
                        setTableData([{
                            "user_name": "TEST",
                            "full_name": "test",
                            "email": "test@email.com",
                            "care_site": "TEst Inst",
                            "role": "Test role",
                            "req_time": "2022-09-21 10:10:04",
                            "status": "Approved"
                        }])
                    });
            }, (error) => {
                console.log("Unable to get care sites", error)
                Swal.fire({
                    icon: "error",
                    title: "Connection Error",
                    allowOutsideClick: false,
                    html: "Unable to connect to the admin server. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                and send a screenshot of this error message<br><br>\
                <div class='outline black'>"+ error + "</div>"
                }).then((response) => { })
            })
    }
    const update_approval = (selected_users, action) => {
        console.log("SU", selected_users, action)
        var selected_user_names = []
        for (let i = 0; i < selected_users.length; i++) {
            selected_user_names.push(tableData[selected_users[i]['dataIndex']]['user_name'])
        }
        axios.post(email_service + "/updateUsers", { "users": selected_user_names, "action": action }, request_config)
            .then((response) => {
                console.log("Update Done");
                retrieve_users();
            }, (error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        console.log("Loading Dashboard")
        if (location.state && location.state.selection) {
            setTabValue(location.state.selection)
        }
        else {

            setTabValue(0)
        }
        retrieve_users();
        // retrieve_tickets();
    }, [])

    return (
        <>
                {tableData.length === 0
                    &&
                    <article className="vh-100 dt w-100 flex items-center">
                        <div className="flex justify-center w-100 white ph3 ph4-l">
                            Loading Results ...
                        </div>
                    </article>
                }
                {
                    tableData.length > 0 &&
                    <div className="mt5">
                        <UserTable data={tableData} updateHandler={update_approval} />
                    </div>
                }
        </>
    )


}

export default UserApproval;
