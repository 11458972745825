import React from 'react';
import App from './App.jsx';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from "react-router-dom";

import { createRoot } from 'react-dom/client';



const container = document.getElementById('root');
const root = createRoot(container);

root.render(
<Authenticator.Provider>
  <BrowserRouter>
      <App />
  </BrowserRouter>
</Authenticator.Provider>
);

