import React, { Component, FC } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {version_num} from './config'
const Copyright = (props) => {

        return (
            <div id="copyright-footer" className="w-100 tc mb2 absolute bottom-2 ">
            <Typography variant="body1" color="black" align="center" {...props}>
                <strong>{'The Wake Up Safe Case Reporting system ('+version_num+') Powered by '}
                {/* <Link color="inherit" href="https://www.research.chop.edu/tsui-laboratory"> */}
                        the Children's Hospital of Philadelphia
                {/* </Link>{' and '} */}
                
                {' © ' + new Date().getFullYear()}
                {'.'}
                    </strong>
            </Typography>
            </div>
        );
    
}

export default Copyright;