import React from "react";
import "../css/App.css";
import { FunctionFactory } from "survey-core";
import { inputmask } from "surveyjs-widgets";
import * as SurveyCore from "survey-core";


import eye_json from './Eye.json'
import airway_json from './Airway.json'
import cardiac_json from './Cardiac.json'
import demo_json from './Demographics.json'
import dental_json from './Dental.json'
import meta_json from './Metabolic.json'
import misc_json from './Miscellaneous.json'
import musc_json from './Musculoskeletal.json'
import neuro_json from './Neurologic.json'
import psych_json from './Psychological.json'
import safety_json from './Safety.json'

inputmask(SurveyCore);
function sqroot(value) {
    return Math.sqrt(value).toPrecision(2)
}
FunctionFactory.Instance.register("sqroot", sqroot);


function IntegerValidator(params) {
    var value = params[0];
    // if(typeof(JSON.parse()))
    if(value){
        return Number.isInteger(value);
    }
    else{

        return true;
    }
  }

FunctionFactory.Instance.register("IntegerValidator",IntegerValidator);

function getJSON(surveyName ){
        let json = {}

        switch(surveyName){
            case "eye":
                json = eye_json;
                break;

            case "airway":
                json = airway_json;
                break;

            case "cardiac":
                json = cardiac_json;
                break;

            case "neurology":
                json = neuro_json;
                break;

            case "psych":
                json = psych_json;
                break;

            case "dental":
                json = dental_json
                break;

            case "meta":
                json = meta_json
                break;

            case "musculoskeletal":
                json = musc_json
                break;

            case "misc":
                json = misc_json
                break;

            case "demo":
                json = demo_json
                for(let item_idx in json["pages"][0]["elements"]){
                    // console.log("ITEM",item_idx)
                    // if (json["pages"][0]["elements"][item_idx]["name"]==="45e48b52-7e40-5b70-a949-2e7149d2376e"){
                    //     let icd10_codes = icd_json['codes']
                    //     json["pages"][0]["elements"][item_idx]["choices"] = icd10_codes
                    // }
                    // if (json["pages"][0]["elements"][item_idx]["name"]==="93a08765-9793-5934-a866-40b48b8fbefa"){
                    //     let cpt_codes = cpt_json['codes']
                    //     json["pages"][0]["elements"][item_idx]["choices"] = cpt_codes
                    // }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="2add8390-8531-5c44-8c0a-e7d5926d902f"){
                        // json["pages"][0]["elements"][item_idx]["max"] =
                        let current_year =  new Date().getFullYear()
                        json["pages"][0]["elements"][item_idx]["validators"] = [
                            {
                              "type": "expression",
                              "text": "Input should be an Integer",
                              "expression": "IntegerValidator({2add8390-8531-5c44-8c0a-e7d5926d902f}) = true"
                            },
                            {
                                "type": "expression",
                                "text": "Value should not be less than 2020",
                                "expression": "{2add8390-8531-5c44-8c0a-e7d5926d902f}=='' or {2add8390-8531-5c44-8c0a-e7d5926d902f} > 2019 "
                            },
                            {
                                "type": "expression",
                                "text": "Value should not be more than " + current_year ,
                                "expression": "{2add8390-8531-5c44-8c0a-e7d5926d902f}=='' or {2add8390-8531-5c44-8c0a-e7d5926d902f} <= " + current_year
                            },
                          ]
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="7a0b666d-ffe5-54a9-8cff-226cacfa05ae"){
                        json["pages"][0]["elements"][item_idx]["validators"] = [
                            {
                              "type": "expression",
                              "text": "Input should be an Integer",
                              "expression": "IntegerValidator({7a0b666d-ffe5-54a9-8cff-226cacfa05ae}) = true"
                            }
                          ]
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="8f13f86d-fb5c-504c-bac8-f195d598ef93"){
                        
                        json["pages"][0]["elements"][item_idx]["validators"]=[
                            {
                                "type": "expression",
                                "text":"Year should be between 0 and 100",
                                "expression": " ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} == '') or ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} >= 0) and ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} <= 100)"
                            },
                            {
                                "type": "expression",
                                "text":"Month should be between 0 and 11",
                                "expression": " ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} == '') or  ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} >= 0) and ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} < 12)"
                            },
                            {
                                "type": "expression",
                                "text": "Year should be an Integer",
                                "expression": "IntegerValidator({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889}) = true"
                              },
                              {
                                "type": "expression",
                                "text": "Month should be an Integer",
                                "expression": "IntegerValidator({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8}) = true"
                              }
                        ];
                    }

                }
                break;
            case "contributory":
                json = safety_json;
                break;

            default:
                alert("Can not find your specified survey.");
        };

        return json;

    }

export function pagesJSON(newElements){
    var new_pages=[];
    newElements.forEach((element) => {
        let elementJSON = getJSON(element);
        new_pages.push(elementJSON['pages'][0]);
    });
    return new_pages;

}
export function composeJSON(selectedElements){

        const elements = selectedElements;
        let surveyJSON = {
            "progressBarType": "buttons",
            "showProgressBar": "top",
            "pages": []
        };

        let demoJSON = getJSON("demo");
        surveyJSON['pages'].push(demoJSON['pages'][0]);
        elements.forEach((element) => {
            let elementJSON = getJSON(element);
            surveyJSON['pages'].push(elementJSON['pages'][0]);
        });
        let contributoryJSON = getJSON("contributory");
        surveyJSON['pages'].push(contributoryJSON['pages'][0]);
        surveyJSON["checkErrorsMode"]="onValueChanged"
        surveyJSON["clearInvisibleValues"] = "onHidden"
        surveyJSON["triggers"]= [
            {
                "type": "runexpression",
                "expression": "{c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951}!=0",
                "setToName": "71cd114e-d13e-5a82-8257-9d7ec9d726e7",
                "runExpression": " {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} / ({b1de9a34-315f-5f96-bc14-c1c8d6968951}*{b1de9a34-315f-5f96-bc14-c1c8d6968951}) "
            }
            // {
            //     "type": "runexpression",
            //     "expression": "{c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} isempty and {71cd114e-d13e-5a82-8257-9d7ec9d726e7} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951} notempty ",
            //     "setToName": "c55a2bbb-0169-5584-a1d4-4d62f79cc9ad",
            //     "runExpression": " {71cd114e-d13e-5a82-8257-9d7ec9d726e7} * ({b1de9a34-315f-5f96-bc14-c1c8d6968951}*{b1de9a34-315f-5f96-bc14-c1c8d6968951}) "
            // },
            // {
            //     "type": "runexpression",
            //     "expression": "{b1de9a34-315f-5f96-bc14-c1c8d6968951} empty and {71cd114e-d13e-5a82-8257-9d7ec9d726e7} notempty and {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} notempty and {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad}!=0 ",
            //     "setToName": "b1de9a34-315f-5f96-bc14-c1c8d6968951",
            //     "runExpression": " sqroot({c55a2bbb-0169-5584-a1d4-4d62f79cc9ad}/{71cd114e-d13e-5a82-8257-9d7ec9d726e7}) "
            // }
        ]

        return surveyJSON;
}

