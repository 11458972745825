import "./css/tachyons.min.css";
// import { Amplify, Auth, Logger } from 'aws-amplify';
import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';

const Pending = (props)=>{
  
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    
     
  }, []);
  
  if(props.status==="Rejected"){

    return (
      <>
      <article className="vh-50 dt w-100 flex items-center">
      <div className="flex justify-center tc w-100 ph3 ph4-l">
        Wake Up Safe Admin has rejected your request for access <br/><br/>
        Please contact admin for further enquiries. 
        <br/>
    
    </div>
    </article>
    <br/>
    <div className="w-100 flex justify-center items-center mh3">
    <Button sx={{backgroundColor: "#5AC69F", borderColor: "#5AC69F"}} className="w-5" href="" onClick={signOut}>Logout</Button>
    </div>
    </>
    );
  }
  
  else if (props.status === "Pending"){
    return (
      <>
      <article className="vh-50 dt w-100 flex items-center">
      <div className="flex justify-center tc w-100 ph3 ph4-l">
      Waiting for Admin Approval from the Wake Up Safe Voting Member at your care site<br/><br/>You will receive an email once your request has been reviewed. 
    </div>
    </article>
    <br/>
    <div className="w-100 flex justify-center items-center mh3">
    <Button sx={{backgroundColor: "#5AC69F", borderColor: "#5AC69F"}} className="w-5" href="" onClick={signOut}>Logout</Button>
    </div>
    </>
    );
  }
  else if (props.status === "Error") {
    return (
      <>
        <article className="vh-50 dt w-100 flex items-center">
          <div className="flex justify-center tc w-100 ph3 ph4-l">
            There was an error<br />
          </div>
        </article>
        <br />
        <div className="w-100 flex justify-center items-center mh3">
          <Button sx={{ backgroundColor: "#5AC69F", borderColor: "#5AC69F" }} className="w-5" href="" onClick={signOut}>Logout</Button>
        </div>
      </>
    );
  }
  else{
    return(<div></div>);
  }
}

export default Pending;