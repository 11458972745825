import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import CustomFooter from "./CustomFooter";


const muiCache = createCache({
    "key": "mui",
    "prepend": true
});
const columns = [
    {
        name: "user_name",
        label: "User Name",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "full_name",
        label: "Full Name",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "email",
        label: "Email",
        options: {
            filter: false,
            sort: true,
        }
    },

    {
        name: "care_site",
        label: "Care Site",
        options: {
            filter: true,
            filterType: "dropdown",
            sort: true,
        }
    },
    {
        name: "role",
        label: "Role",
        options: {
            filter: true,
            filterType: "dropdown",
            sort: true,
        }
    },
    {
        name: "req_time",
        label: "Request Time",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "status",
        label: "User Approval",
        options: {
            filter: true,
            sort: false,
            setCellProps: (cellValue, rowIndex, columnIndex) => {
                if (cellValue === 'Rejected') {

                    return {
                        style: {
                            backgroundColor: "#ff725c",
                        },
                        align: 'center',
                    };
                }
                else if (cellValue === "Approved") {
                    return {
                        style: {
                            backgroundColor: "#9eebcf",
                        },
                        align: 'center',
                    };

                }
                else {
                    return {
                        style: {
                            backgroundColor: "#fbf1a9",
                        },
                        align: 'center',
                    };

                }
            }
        }
    },

];



const UserTable = (props) => {

    var selected_rows = [];
    const options = {
        print: false,
        filterType: 'checkbox',
        sortOrder: {
            name: 'req_time',
            direction: 'desc',
        },
        rowsPerPage: 10,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            console.log("ALL:", allRowsSelected, "RS:", rowsSelected, "CR:", currentRowsSelected)
            selected_rows = allRowsSelected;
        },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
                <CustomFooter
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    changePage={changePage}
                    textLabels={textLabels}
                    approve={() => { props.updateHandler(selected_rows, "approve") }}
                    reject={() => { props.updateHandler(selected_rows, "reject") }}
                    green_label={"Approve"}
                    red_label={"Reject"}
                />
            );
        },
    };
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        fontWeight: 'bold'
                    },
                    
                    sortAction: {
                        display: 'flex',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                    },
                    dragCursor: {
                        cursor: 'grab',
                        fontWeight: 'bold'
                    },
                    sortLabelRoot: {
                        height: '20px',
                        fontWeight: 'bold'
                    },
                }
            },
        }
    })
    var data = props.data
    var sorted_data = data.sort((a, b) => (a.req_time > b.req_time) ? 1 : -1)
    return (
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={<span class="fl b f4">User Access Dashboard</span>}
                    data={sorted_data}
                    columns={columns}
                    options={options}
                />
            </ ThemeProvider>
        </ CacheProvider>
    )

}

export default UserTable;