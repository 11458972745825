import React from "react";
import './css/tachyons.min.css'
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiTablePagination from "@mui/material/TablePagination";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const CustomFooter = (props) => {
    const footerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'unsafe center',
        padding: '0px 24px 0px 24px'
    };

    const handleRowChange = event => {
        props.changeRowsPerPage(event.target.value);
    };

    const handlePageChange = (_, page) => {
        props.changePage(page);
    };
    return (
        <TableFooter>
            <TableRow>
                <Stack spacing={2} mt={2} ml={2} direction="row">
                    <Button variant="contained" color="success" onClick={props.approve}>{props.green_label}</Button>
                    <Button variant="contained" color="error" onClick={props.reject}>{props.red_label}</Button>
                </Stack>
                <TableCell style={footerStyle} colSpan={1000}>
                    <MuiTablePagination
                        component="div"
                        count={props.count}
                        rowsPerPage={props.rowsPerPage}
                        page={props.page}
                        labelRowsPerPage={props.textLabels.rowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${props.textLabels.displayRows} ${count}`}
                        backIconButtonProps={{
                            'aria-label': props.textLabels.previous,
                        }}
                        nextIconButtonProps={{
                            'aria-label': props.textLabels.next,
                        }}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowChange}
                    />
                </TableCell>
            </TableRow>
        </TableFooter>
    );
}

export default CustomFooter;