import React, { FC, useEffect, useState, useCallback } from "react";
import { createRoot } from 'react-dom/client';

import { StylesManager, Model } from "survey-core";
import { PageModel } from "survey-core";
import { Survey } from "survey-react-ui";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { composeJSON, pagesJSON } from "./survey_templates/SurveyComposer";
import { storeWebSession, storeSurveySession } from "./utility/DbService";
import { useInterval } from "./useInterval";
import Swal from 'sweetalert2';
// import DDButton from "./DropDownButton";
import "./css/tachyons.min.css"

import { v5 as uuidv5 } from 'uuid';
import { Tooltip } from 'react-tooltip';
import optionDescriptions from "./survey_templates/choiceDescription.json"
import { MUIDropDown } from "./MUIdropDown";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
//Import localization
import './index.css';
import axios from "axios";
import { service_url } from "./config";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

var surveyloadcounter = 0;
const LoadEditableSurvey = props => {
    var retry_counter = 0;
    var ques_info_flag = false;
    // const location = useLocation();
    var selected = [];
    let query = useQuery();
    
    let navigate = useNavigate();
    const [surveyPayload, setsurveyPayload] = useState(null);
    // const [loadFlag, setFlag] = useState(false)

    const surveyUUID = query.get("surveyUUID")
    const reviewType = query.get("type");
    var page_json_map = {
        "Neurologic": "neurology", "Psychological": "psych", "Airway/Respiratory": "airway", "Eye": "eye", "Cardiac": "cardiac",
        "Dental": "dental", "Metabolic": "meta", "Musculoskeletal": "musculoskeletal", "Miscellaneous": "misc"
    }
    var old_page_json_map = {
        "03": "neurology", "08": "psych", "00": "airway", "02": "eye", "05": "cardiac",
        "06": "dental", "04": "meta", "01": "musculoskeletal", "09": "misc"
    }
    // const surveyUUID = query.get("surveyUUID");

    const loadSurveyFromDB = (surveyUUID) => {
        var dataString = { 
            "surveyUUID": surveyUUID, 
            "reviewFlag": query.get("type")
        };
        var config = {
            headers: { 'Access-Control-Allow-Origin': '*' }
        };

        axios.post(service_url + "/surveysession/getSurvey", dataString, config)
            .then(function (response) {
                // console.log(response.data);
                setsurveyPayload(JSON.parse(response.data));
                // setFlag(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    //Compose the Survey based on incoming events
    // StylesManager.applyTheme("modern");
    var survey = new Model();
    survey.applyTheme(SurveyTheme.DefaultLightPanelless);
    var json;
    var data;

    var incoming_events = []
    useEffect(() => {
        sessionStorage.setItem("survey_uuid", JSON.stringify(surveyUUID))
        // @ts-ignore
        loadSurveyFromDB(surveyUUID);
        
    }, []);


    if (surveyPayload) {
        if ("pages" in surveyPayload) {

            for (let i = 0; i < surveyPayload["pages"].length; i++) {
                if (surveyPayload["pages"][i] === 'Somatic') {
                    incoming_events.push(page_json_map["Musculoskeletal"])
                }
                else {
                    incoming_events.push(page_json_map[surveyPayload["pages"][i]])
                }
            }
            
            json = composeJSON(incoming_events);
            data = surveyPayload["data"];
        }
        else {
            
            json = surveyPayload["questionnaire"];
            for (let p =0;p<json['pages']['elements'].length;p++){
                let ques_id = json['pages']['elements'][0]['name'].substring(0,2)
                incoming_events.push(old_page_json_map[ques_id])
            }
            console.log("Loading questionnaire");
            data = surveyPayload["data"];
        }
        survey.setJsonObject(json);
        survey.data = data;
        // setIncomingEvents(incoming_events)
    } 
    var render_counter = 0;
    surveyloadcounter += 1;
    

    const removePages = (remove_pages) => {
        if (remove_pages.length === 0) return;
        // console.log("REMOVING PAGES", remove_pages)
        var remove_pages_json = pagesJSON(remove_pages);
        for (let k = 0; k < remove_pages_json.length; k++) {
            // Rework this
            var removed_page_name = remove_pages_json[k]['name']
            console.log(removed_page_name)
            var page = survey.getPageByName(removed_page_name)
            var question_names = []
            page.questions.forEach((element) => {
                question_names.push(element.name)
            });
            console.log(question_names)
            //Clear page before removal
            let current_data = survey.data

            let new_data = {}
            for (let ques in current_data) {
                // console.log(typeof(ques),ques)
                if (question_names.includes(ques)) {
                    console.log("Deleting:", ques)
                    delete current_data[ques]
                }
            }
            // console.log(new_data);
            survey.data = current_data;
            survey.removePage(page)

            var removal_index = 0;
            for (let j = 0; j < json["pages"].length; j++) {
                if (json["pages"][j]["name"] === remove_pages_json[k]['name']) {
                    removal_index = j;
                    break
                }
            }
            json["pages"].splice(removal_index, 1)
        }
    }
    const addPages = (add_pages) => {
        // console.log("ADDING PAGES", add_pages)
        if (add_pages.length === 0) return;
        var new_pages_json = pagesJSON(add_pages);
        for (let k = 0; k < new_pages_json.length; k++) {

            var page = new PageModel()
            page.fromJSON(new_pages_json[k])
            // survey.createNewPage(new_pages_json[k]['name'])
            // page.fromJSON(new_pages_json[k])

            //
            survey.addPage(page, survey.pageCount - 1)
            // survey = cloneDeep(survey)
            //Change State : 
            // survey.render();
            json["pages"].splice(json["pages"].length - 1, 0, new_pages_json[k]);
        }
        //reset the dropdown location
    }
    const event_changes = (obj) => {
        // console.log(obj)
        addPages(obj["add"])
        removePages(obj["remove"])
    }

    function doOnCurrentPageChanged(options) {

        // @ts-ignore
        var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');

        for (var i = 0; i < elemets.length; i++) {
            var div = elemets[i];
            div.setAttribute("style", "font-size:13px");
        }

    }
    survey.completeText = "Submit";


    //Called everytime a survey page is rendered
    survey
        .onAfterRenderHeader
        .add((sender, option) => {
            render_counter += 1;
            console.log("RENDER", render_counter)


        });
    survey
        .onPageAdded
        .add((sender, option) => {

            console.log("PAGE_ADDED")

        });
    survey
        .onAfterRenderQuestion
        .add((sender, option) => {

            let page_name = option.question.page.name;
            let question_name = option.question.name
            // console.log(option.question.name)

            if (page_name === 'Demographics' && !ques_info_flag) {
                setTimeout(() => {
                    let asa_ques_id_in_static = "0624cfe0-8375-52b4-9076-71d701257e310"
                    let asa_message = "<a href='https://www.asahq.org/standards-and-guidelines/asa-physical-status-classification-system' target='_blank'>ASA Physical Status Classification</a>"

                    var element = document.querySelector('[data-key="' + asa_ques_id_in_static + '"]').querySelector('[class="sv-title-actions__title"]').querySelector('[class="sv-string-viewer sv-string-viewer--multiline"]')
                    //     console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]'))
                    //     console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]').querySelector('[class="sv-title sv-question__title"]'))
                    // console.log("QUERYSELECTOR:",element)
                    var sup = document.createElement("sup");
                    sup.innerHTML = '<button data-tooltip-id="info-' + asa_ques_id_in_static + '" \
                    data-tooltip-delay-hide=500 \
                    class="br-100 f6 ba b--blue bg-blue p-help white"> ? </button>';
                    var span = document.createElement("span");
                    span.innerHTML = "  ";
                    span.id = "span-" + asa_ques_id_in_static;
                    element.appendChild(span);
                    element.appendChild(sup);
                    const container = document.getElementById("span-" + asa_ques_id_in_static)
                    const root = createRoot(container)
                    root.render(<Tooltip
                        opacity={1}
                        id={"info-" + asa_ques_id_in_static}
                        place="right"
                        className="clickableLink"
                        style={{ backgroundColor: "#EEDC82", zIndex: 100 }}
                        html={asa_message}
                    />)
                    // setTimeout(() => {
                    var tooltip = document.getElementById("info-" + asa_ques_id_in_static)
                    if (tooltip) {
                        tooltip.style.marginRight = "4em"
                    }
                    // console.log(tooltip.innerHTML)
                    // }, 500);
                    // console.log(element);

                }, 500)
                ques_info_flag = true;

            }
            if (!(page_name in optionDescriptions))
                return

            setTimeout(() => {
                for (let key in optionDescriptions[page_name][question_name]) {

                    let message = optionDescriptions[page_name][question_name][key]
                    var element = document.querySelector('input[value="' + key + '"]').parentNode.querySelector('.sd-item__control-label').querySelector('.sv-string-viewer')
                    var sup = document.createElement("sup");
                    sup.innerHTML = '<button data-tooltip-id="info-option-' + key + '" data-tooltip-delay-hide=200  class="br-100 f6 ba b--blue bg-blue p-help white"> ? </button>';
                    var span = document.createElement("span");
                    span.innerHTML = "  ";
                    span.id = "span-" + key;
                    element.appendChild(span);
                    element.appendChild(sup);
                    const container = document.getElementById("span-" + key)
                    const root = createRoot(span)
                    root.render(<Tooltip
                        id={"info-option-" + key}
                        place="right"
                        effect="solid"
                        style={{
                            backgroundColor: "rgba(230,220,130,1)",
                            width: "30em",
                            zindex: 999,
                            opacity: 1
                        }}
                        html={'<span class="b ma0 pa0 tl black">' + message + '</span>'}
                    />)
                    // setTimeout(()=>{
                    //     var tooltip = document.getElementById("info-"+key)
                    //     if(tooltip){
                    //         tooltip.style.marginLeft="4em"
                    //     }
                    // },500);
                }
            }, 500);
        });

    const retry_func = () => {
        retry_counter += 1;
        document.body.style.cursor = 'default';
        if (retry_counter == 3) {
            Swal.fire({
                icon: 'error',
                title: 'We are sorry for the Inconvenience...',
                text: 'Survey Not Submitted. Max Retries exceeded',
                footer: '<a href="#">Contact Support</a>'
            }).then(() => {

                // window.location.replace("/");
                navigate('/')
                // return <Redirect to='/'/>
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'We are sorry for the Inconvenience...',
                text: 'Survey Not Submitted. Please Retry',
                // footer: '<a href="#">Contact Support</a>'
            }).then(() => {

            })
        }
    }
    //What happens while submitting the survey

    var data_submitted = false;
    survey.onComplete.add((sender, options) => {
        // alert("Done");                

        setTimeout(
            () => {
                navigate("/");
            }, 2000)
    });

    function showDescription(question) {

    }

    survey
        .onCompleting
        .add(function (sender, options) {
            if (data_submitted) {

                document.body.style.cursor = 'default';
                return //For the second call
            }
            // this.style.cursor = "wait";
            document.body.style.cursor = 'progress';
            options.allowComplete = false;



            let survey_events = json["pages"].map((page) => (page["name"]))
            survey_events.shift();
            survey_events.pop();
            let surveyObj = {
                surveyUUID: surveyUUID,
                pages: survey_events,
                data: survey.data
            };
            sessionStorage.setItem('case_submit_flag', JSON.stringify(true));
            sessionStorage.setItem('survey_payload', JSON.stringify(surveyObj));

            let wspromise = storeWebSession();
            let sspromise = storeSurveySession();
            // console.log(wspromise)
            wspromise.then(() => {
                sspromise.then(() => {
                    options.allowComplete = true;
                    console.log(surveyUUID)
                    console.log("Survey Complete Databse Dump")
                    data_submitted = true;
                    sender.doComplete();

                }).catch(() => {
                    retry_func();
                });
            }).catch(() => {
                retry_func();
            })

        })

    // survey.onCurrentPageChanged.add((sender, options) => { doOnCurrentPageChanged(options) });

    // useEffect(() => {

    //     setCaseSubmitFlag(false);
    //     setFlag(true);

    // }, []);

    survey.onChoicesLazyLoad.add((_, options) => {
        if (options.question.getType() === "tagbox" && options.question.name === "45e48b52-7e40-5b70-a949-2e7149d2376e") {


            const url = service_url + '/get_paginated_codes?' + `skip=${options.skip}&take=${options.take}&filter=${options.filter}&code=icd`
            axios.get(url).then((response) => {
                options.setItems(response.data.codes, response.data.total);
            });
        }
        if (options.question.getType() === "tagbox" && options.question.name === "93a08765-9793-5934-a866-40b48b8fbefa") {
            const url = service_url + '/get_paginated_codes?' + `skip=${options.skip}&take=${options.take}&filter=${options.filter}&code=cpt`
            console.log(options)
            axios.get(url).then((response) => {
                options.setItems(response.data.codes, response.data.total);
            });
        }
    });
    useInterval(() => {
        // console.log(surveyUUID)
        let survey_events = json["pages"].map((page) => (page["name"]))
        survey_events.shift();
        survey_events.pop();
        sessionStorage.setItem('survey_payload', JSON.stringify({
            surveyUUID: surveyUUID,
            pages: survey_events,
            data: survey.data
        }))
        //Call DB Service
        // console.log(surveyUUID, webSessionID);
        storeSurveySession();
        // console.log(survey_events)
    }, 10000);

    return(

        <>
        { !surveyPayload && 
            <div> Loading Questionnaire ...</div>
        }
        {surveyPayload && 
                <Box className="fl mt6 pa3 w-100 tl">
                <Survey model={survey} />
                <MUIDropDown id_str="add_event" event_changes={event_changes} original_events={incoming_events} />
            {/* <DDButton id_str="add_event" event_changes={event_changes} original_events={incoming_events} /> */}
            </Box>
        }
        </>
    )

};
export default LoadEditableSurvey;
